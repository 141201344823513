import Link from "next/link";
import { useRouter } from "next/router";
import useSWR from "swr";
import { getter } from "../api";
import { News, Success } from "../api/types";
import Avatar from "./Avatar";
import SpinnerCustom from "./SpinnerCustom";
import { dateFormatDDMMMYYYY } from "./Utils/commons";

const Tooltip = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return (
    <div
      id="tooltip-bottom"
      role="tooltip"
      className={`absolute z-top ${className} max-w-xs break-all top-full invisible group-hover:visible inline-block py-1 px-2 text-xs w-max font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip`}
    >
      {value}
    </div>
  );
};

const options = {
  revalidateOnFocus: false,
  revalidateOnMount: true,
  shouldRetryOnError: false,
};

const LatestNews = () => {
  const router = useRouter();
  const { data: latest_news } = useSWR<Success<News[]>>(
    "/api/people_map/news?dashboard=True&limit=50",
    getter,
    options
  );

  return (
    <div className="bg-white shadow-sm p-4 !pr-0 rounded-lg border border-1 lg:h-[60%] sm:w-1/2 lg:w-full whitespace-normal">
      <div className="h-full">
        <div className="flex flex-row items-center my-3">
          <span className="h-5 w-2 mr-2 bg-blue-menu"></span>
          <div className="font-bold text-sm sm:text-xs md:text-sm mr-2">
            News
          </div>
        </div>
        <div className="flex flex-col sm:h-[92%] !h-fit max-h-[92%] sm:max-h-[88%] overflow-y-auto pr-6 show-scrollbar ">
          {latest_news && latest_news.data ? (
            latest_news.data.map((latest_new: News) => (
              <div
                key={latest_new.id}
                className="border-b-[1px] border-gray-300 py-2"
              >
                <div className="flex flex-row gap-x-2 items-center">
                  {latest_new.organizations.map((org) => (
                    <Link
                      href={`/organizations/${org.id}`}
                      key={org.id}
                      className="group relative"
                    >
                      <Avatar
                        name={org.name}
                        size={50}
                        url={org.image_url}
                        className="shadow-sm rounded-none"
                      />
                      <Tooltip value={org.name} className="left-0" />
                    </Link>
                  ))}
                  <Link
                    href={latest_new.url}
                    className="w-full"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex flex-col relative">
                      <span className="line-clamp-2 text-black text-xs font-semibold text-left break-normal">
                        {latest_new.title}
                      </span>
                      <div className="flex flex-row justify-between font-light text-black text-2xs">
                        <div>{latest_new.source}</div>
                        <div>
                          {dateFormatDDMMMYYYY(latest_new.publish_date)}
                        </div>
                      </div>
                      <Tooltip
                        value={latest_new.url}
                        className="left-1/2 right-1/2 -translate-x-1/2"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <SpinnerCustom />
          )}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
