import {
  ArrowTopRightOnSquareIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useState } from "react";
import { CollaborationThread } from "../../api/types";
import ForumView from "../Forum/ForumView";
import { ThreadForm } from "../Forum/ThreadForm";

export const NetWorkCollaboration = () => {
  const [newThread, setNewThread] = useState(false);
  const [newThreadId, setNewThreadId] = useState<number>();

  const updateThread = (thread: CollaborationThread) => {
    setNewThread(false); //Close form
    setNewThreadId(thread.id); // update new thread on list
  };

  return (
    <div className="lg:h-[40%] sm:w-1/2 lg:w-full lg:pt-3 pb-1">
      <div className="bg-white shadow-sm p-4 !pr-0 rounded-lg border border-1 min-w-[270px] h-full overflow-hidden">
        <div className="flex flex-row items-center pr-4">
          <span className="h-5 w-2 mr-2 bg-blue-menu"></span>
          <div className="font-bold text-sm sm:text-xs md:text-sm mr-2">
            Network Collaboration
          </div>
          <PlusCircleIcon
            className="h-5 w-5 stroke-blue-700 cursor-pointer mr-1"
            onClick={() => setNewThread(true)}
          />
          <Link href={`/collab`}>
            <ArrowTopRightOnSquareIcon className="mb-1 h-5 w-5 stroke-blue-menu" />
          </Link>
        </div>
        {newThread && (
          <div className="h-[92%] overflow-auto pr-6">
            <ThreadForm
              onClose={() => setNewThread(false)}
              onSuccess={updateThread}
            />
          </div>
        )}
        <div className="!h-[88%] overflow-auto pr-6 show-scrollbar">
          <ForumView newThreadId={newThreadId} />
        </div>
      </div>
    </div>
  );
};
