import { Tab } from "@headlessui/react";
import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { getter } from "../../api";
import {
  DashboardOrganization,
  OrganizationInvestmentDetail,
  Success,
} from "../../api/types";
import Avatar from "../Avatar";
import SpinnerCustom from "../SpinnerCustom";
import { dateFormatDDMMMYYYY } from "../Utils/commons";
import { classNames as classes } from "../utils";
import { options } from "./DashboardUltils";

export const Companies = () => {
  const [index, setIndex] = useState(0);

  const { data: vpcs, isValidating: loadingVpcs } = useSWR<
    Success<OrganizationInvestmentDetail[]>
  >("/api/people_map/vpcs/recent", getter, options);

  const { data: organizations, isValidating: loadingOrgs } = useSWR<
    Success<DashboardOrganization[]>
  >("/api/people_map/organizations/recent", getter, options);

  useEffect(() => {
    const prevTab = localStorage.getItem("dashboardCompaniesTab") ?? "0";
    setIndex(parseInt(prevTab));
  }, []);

  const tabHeaders = useMemo(() => {
    return [
      {
        index: 0,
        name: "Recently Invested",
      },
      {
        index: 1,
        name: "New",
      },
    ];
  }, []);

  return (
    <div className="flex flex-row items-start w-full bg-white shadow-sm p-4 !pr-0 rounded-lg border border-1 min-w-[270px] max-h-[72vh] h-full">
      <Tab.Group
        selectedIndex={index}
        onChange={(index) => {
          setIndex(index);
          localStorage.setItem("dashboardCompaniesTab", index.toString());
        }}
        defaultIndex={index}
      >
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-row items-end justify-between my-3 items-center pr-4">
            <Tab.List className="flex w-full items-center justify-between">
              <div className="flex">
                <span className="h-5 w-2 mr-2 bg-blue-menu"></span>
                <div className="font-bold text-sm sm:text-xs md:text-sm mr-2">
                  Companies
                </div>
              </div>
              <div className="flex items-center border-b-[1px] border-gray-300 gap-x-2">
                {tabHeaders.map((tabHeader) => (
                  <div key={tabHeader.index} className="group">
                    <Tab
                      className={({ selected }) =>
                        classes(
                          "whitespace-nowrap px-1 text-xs sm:text-2xs md:text-xs font-medium focus:outline-none focus:ring-0",
                          selected || tabHeader.index === index
                            ? "text-blue-menu"
                            : "text-gray-500 group-hover:text-gray-700"
                        )
                      }
                    >
                      {tabHeader.name}
                    </Tab>
                    <div
                      className={`h-1 w-full rounded-t-md ${
                        tabHeader.index === index
                          ? "bg-blue-menu"
                          : "group-hover:bg-gray-300"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
            </Tab.List>
          </div>
          <Tab.Panels className="sm:h-full !h-fit overflow-auto pr-6 show-scrollbar">
            <Tab.Panel>
              <div className="flex flex-col text-xs">
                {!loadingVpcs ? (
                  vpcs &&
                  vpcs.data.map((vpc: OrganizationInvestmentDetail) => (
                    <Link
                      className="flex flex-col border-b-[1px] border-gray-300 py-2 gap-y-2 text-black"
                      key={vpc.id}
                      href={`/organizations/${vpc.investee_organization.id}`}
                    >
                      <div className="flex gap-x-2">
                        <Avatar
                          name={vpc.investee_organization.name}
                          size={50}
                          url={vpc.investee_organization.image_url}
                          className="shadow-sm rounded-none"
                        />
                        <div className="flex flex-col">
                          <div className="font-bold">
                            {vpc.investee_organization.name}
                          </div>
                          <div className="line-clamp-2 break-normal">
                            {vpc.investee_organization.description}
                          </div>
                        </div>
                      </div>
                      {vpc.investment_date && (
                        <div className="flex items-center justify-between text-gray-500">
                          <div>{dateFormatDDMMMYYYY(vpc.investment_date)}</div>
                          <div>{vpc.investor_fund.name}</div>
                        </div>
                      )}
                    </Link>
                  ))
                ) : (
                  <SpinnerCustom />
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-col text-xs">
                {!loadingOrgs ? (
                  organizations &&
                  organizations.data.map((org: DashboardOrganization) => (
                    <Link
                      className="flex flex-col border-b-[1px] border-gray-300 py-2 gap-y-2 text-black"
                      key={org.id}
                      href={`/organizations/${org.id}`}
                    >
                      <div className="flex gap-x-2">
                        <Avatar
                          name={org.name}
                          size={50}
                          url={org.logo_url}
                          className="shadow-sm rounded-none"
                        />
                        <div className="flex flex-col">
                          <div className="font-bold">{org.name}</div>
                          <div className="line-clamp-2 break-normal">
                            {org.description}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <SpinnerCustom />
                )}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};
